footer {
    margin-top: 60px;
    padding: 20px 0;
    border-top: 1px solid $border;
    p {
        color: $gray;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }
}