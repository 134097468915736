.upload-area {
	border: 1px solid $border;
    border-radius: 10px;
    .input-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 40px;
        padding: 5px;
        .drag {
            color: $gray;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
        }
        .input-box {
            margin-left: 20px;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $blue;
                border-radius: 50%;
                border: none;
                width: 25px;
                height: 25px;
                padding: 0;
                cursor: pointer;
            }
        }
        .error-text {
            display: block;
            width: 100%;
            text-align: center;
        }
        .upload-result-text {
            display: block;
            width: 100%;
            color: $gray;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            margin: 5px 0 0 0;
        }
    }
}