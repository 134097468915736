.btn-wrap {
    .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: max-content;
        background-color: rgba(0, 52, 130, 0.10);
        color: $blue;
        position: relative;
        border: 1px solid $border;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        text-align: center;
        transition: .3s;
        padding: 7px 9px 7px 20px;
        min-width: 250px;
        border-radius: 10px;
        &:hover, &:focus-visible {
            border-color: $blue;
            .arrow {
                svg {
                    animation: arrowMove 1.5s infinite;
                }
            }
        }
        &.arrow {
            padding: 12px 70px 12px 17px;
        }
        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            transition: .3s ease-out;
            background-color: $blue;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            svg {
                
            }
        }
        .plus {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            background: $blue;
            animation: addMore 1.5s infinite;
            svg {

            }
        }
    }
}


@keyframes addMore {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes arrowMove {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0);
    }
}