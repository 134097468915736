.input-wrap {
    input, textarea, select, .select {
        border: 1px solid $border;
        color: $gray;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        padding: 11px 38px 12px 20px;
        outline: none;
        border-radius: 10px;
        min-height: 41px;
        &::placeholder {
            color: $gray;
        }
    }
    textarea {
        resize: none;
        height: 195px;
    }
    &.error {
        input, textarea, select, .select {
            border-color: $error;
        }
    }
}

.input-date-picker-wrap {
    position: relative;
    max-width: 100%;
    width: 100%;
    &.error {
        .date-input {
            .react-datepicker-input {
                border-color: $error !important;
            }
        }
    }
    label {
        display: block;
        color: $gray;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
    }
    .date-input {
        .react-datepicker-input {
            border-radius: 10px;
            border: 1px solid $border !important;
            background: transparent !important;
            min-height: 41px;
            min-width: initial;
            &:hover {
                background: transparent !important;
            }
            input {
                color: $gray!important;
                font-size: 14px!important;
                font-style: normal!important;
                font-weight: 400 !important;
                line-height: normal!important;
                width: 100%!important;
                padding: 11px 38px 12px 20px !important;
                outline: none!important;
                cursor: pointer;
                min-height: 41px;
                &::placeholder {
                    color: $gray !important;
                    font-size: 14px!important;
                    font-style: normal!important;
                    font-weight: 400 !important;
                }
            }
        }
    }
}

.group-checkbox {
    margin-bottom: 16px;
    &.input-height {
        .checkbox {
            align-items: center;
            min-height: 41px;
        }
        .icon {
            max-width: 31px;
            min-width: 31px;
            max-height: 31px;
            min-height: 31px;
            .check {
                width: 15px;
                height: 15px;
            }
        }
    }
}
.checkbox {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.error {
        .icon {
            border: 2px solid $error !important;
        }
    }

    span, a {
        color: $gray;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked ~ .icon svg,
    input:checked ~ .icon .check {
        display: block;
    }
    .icon {
        border: 2px solid $border;
        max-width: 14px;
        min-width: 14px;
        max-height: 14px;
        min-height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 9px;
        .check {
            display: none;
            background-color: $blue;
            // width: 8px;
            // height: 8px;
            width: 6px;
            height: 6px;
        }
    }
    svg {
        display: none;
        width: 16px;
        height: 16px;

        path {
            fill: $gray;
        }
    }
}

.error-text {
    margin: 0;
    margin-top: 5px;
    color: $error;
    font-size: 12px;
}

.select {
    max-width: 100%;
    width: 100%;
    // height: 53px; 
    min-height: 41px;
    position: relative;

    &.error {
        .chosen {
            border: 1px solid $error;
        }
        .options {
            
            &.active {
                border: 1px solid $error;
                border-top: 0;
            }
        }
    }
    .chosen {
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 11px 38px 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px $border solid;
        position: relative;
        user-select: none;
        border-radius: 10px;
        color: $gray;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active {
            border-bottom: none;
            border-end-end-radius: 0;
            border-end-start-radius: 0;
        }
    
        .dropdown-bird {
            position: absolute;
            top: 50%;
            right: 9px;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: $border;
            &.active {
    
                svg {
                    transform: rotate(-180deg);
                }
            }
    
            svg {
                // display: block;
                // width: 18px;
                // height: 10px; 
                width: 15px;
                height: 10px;
                transition: .3s;
            }               
        }
    
    }
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        background: $white; 
    }
    ::-webkit-scrollbar-thumb {
        background: $gray; 
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: $gray; 
    }
    ::-moz-selection {
        background: $gray; 
    }
      
    ::selection {
        color: $white;
        background: $gray; 
    }
    .options {
        display: none;
        position: absolute;
        max-width: 499px;
        width: 100%;
        user-select: none;
        z-index: 2;
        transition: .3s;
        max-height: 200px;
        overflow-y: auto;
        &.active {
            display: block;
            padding: 11px 38px 12px 20px;
            border: 1px solid $border;
            border-radius: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
            background-color: $white;
    
            
            p {
                cursor: pointer;
                transition: .4s;
                margin: 10px 0;
                font-size: 14px;
            }
        }
    }
}
