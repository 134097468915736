.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(24, 25, 24, 0.86);
    width: 100%;
    height: 100%;
    z-index: 999999999999;
}
.popup {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;
    height: 300px;
    z-index: 9999999;
    overflow-y: auto;
    @media( max-width: 1050px ) {
        height: auto;
        max-width: 95vw;
        max-height: 95vh;
    }
    .popup-wrapper {
        position: relative;
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .popup-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 45px;
            background-color: $red;
            padding: 0 30px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            span {
                color: #ffffff;
                font-size: 1.4rem;
                cursor: pointer;
                padding: 5px;
                border: 2px solid transparent;
                outline: none;
                &:focus-visible {
                    border-color: #ffffff;
                }
                p {
                    display: inline-block;
                    color: #ffffff;
                    font-size: 1.6rem;
                }
            }
        }
        .popup-content {
            margin-top: 85px;
            height: calc(100% - 120px);
            padding: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            @media( max-width: 1050px ) {
                height: auto;
                padding: 30px;
            }
            .editor {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                max-width: 100%;
                outline: none;
                * {
                    color: $red !important;
                }
                h1,h2 {
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;
                    margin: 0;
                    font-weight: bold;
                }
                p {
                    display: inline-block;
                }
            }
        }
        .popup-footer {
            background-color: $red;
            height: 35px;
            width: 100%;
        }
    }
}