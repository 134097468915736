.label-wrapper {
    margin-bottom: 30px;
    @media( max-width: 800px ) {
        margin-bottom: 15px;
    }
    .title {
        display: flex;
        justify-content: space-between;
        label {
            display: block;
            color: $blue;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .arrow-box {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $border;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            cursor: pointer;
            transition: .3s;
            &.active {
                transform: rotate(180deg);
            }
        }
    }
    .info {
        color: $gray;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }
}